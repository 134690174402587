.components {
  position: relative;
  flex-direction: column;
  max-width: unset;
  padding: unset;
  width: 100%;
  height: unset;
  min-height: 100vh;

  .contents {
    gap: vw(16 * 2);
    padding: vw(16 * 2);
  }

  .components-menu {
    padding: vw(16 * 2);
  }
}

.dropdown-item {
  .dropdown {
    .dropdown-select {
      gap: vw(10 * 2);
      padding: vw(12 * 2) 0;

      p {
        font-size: vw(16 * 2);
      }

      i {
        img {
          width: vw(20 * 2);
        }
      }
    }

    ul.dropdown-option {
      li {
        padding: vw(12 * 2);
        font-size: vw(16 * 2);
        line-height: unset;
      }
    }
  }
}

.tabs {
  .tab-item {
    font-size: vw(18 * 2);
    padding: vw(12 * 2) 0;
  }
}

.steps {
  ul {
    gap: vw(24 * 2);

    li {
      width: vw(40 * 2);
      height: vw(40 * 2);
      font-size: vw(16 * 2);

      &::after {
        left: vw(40 * 2);
        width: vw(54 * 2);
        height: vw(2 * 2);
        background-size: vw(6 * 2) vw(2 * 2);
      }

      &.active {
        box-shadow: inset 0 0 0 vw(1 * 2) $primary;
      }

      &.success {
        box-shadow: inset 0 0 0 vw(1 * 2) $secondary;

        &::after {
          background-size: 100%;
        }

        i {
          width: vw(24 * 2);
          height: vw(24 * 2);
        }
      }
    }
  }
}

.modal {
  button.gnb {
    position: absolute;
    top: 0;
    right: 0;
    width: vw(28 * 2);
  }

  .modal-dialog {
    min-width: vw(320 * 2);
    padding-top: vw(32 * 2) + vw(12 * 2);

    .modal-dialog-inner {
      gap: vw(24 * 2);
      padding: vw(40 * 2) vw(20 * 2);
      border-radius: vw(12 * 2);
    }

    .modal-header {
      text-align: center;
      h5 {
        font-size: vw(20 * 2);
        line-height: 1.5;
      }
    }

    .modal-body {
      gap: vw(24 * 2);

      P.open-the-app {
        font-size: vw(14 * 2);
        font-weight: 700;
        text-decoration: underline;
        color: $G80;
      }
      p.user-email {
        font-size: vw(16 * 2);
        line-height: 1.2;
        padding: vw(20 * 2) vw(40 * 2);
        border-radius: vw(12 * 2);
      }

      .img-item {
        img.qr-img {
          width: vw(63 * 2);
          height: vw(63 * 2);
        }
        img.step-img {
          width: vw(80 * 2);
          height: vw(80 * 2);
        }
      }

      .qr-img {
        padding: 1rem;

        img {
          width: vw(120 * 2);
        }
      }

      .text-group {
        gap: vw(12 * 2);

        p {
          max-width: unset;
          font-size: vw(16 * 2);
        }

        span {
          max-width: unset;
          font-size: vw(14 * 2);
        }
      }

      span.timer {
        font-size: vw(16 * 2);
        font-weight: 700;
        line-height: 1.5;
      }

      .input-group {
        max-width: unset;
      }
    }

    .modal-footer {
      .button-group {
        gap: vw(24 * 2);

        a {
          font-size: vw(16 * 2);
        }
      }
    }
  }
}
