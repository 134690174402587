@import "./common";
@import "./components";

body {
  &:has(#SideMenu.open),
  &:has(.modal.open) {
    overflow: hidden;
  }

  &:has(#ChangePassword) {
    header {
      display: none;
    }
  }
}

header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  &.fixed {
    position: fixed;
    top: 0;
    backdrop-filter: blur(0.75rem);
    animation: SlideDown 0.4s ease-out;
  }

  .after-auth {
    display: none;
  }

  &.auth {
    .after-auth {
      display: flex;
    }
  }
}

.button-group {
  flex: 1;
  display: flex;
  column-count: auto;
  gap: 1.5rem;

  button {
    flex: 1;
  }
}

.container {
  max-width: 75rem;
  margin: 0 auto;
  height: 100%;
  animation: OpacityAni 0.3s;
}

.main {
  height: 61.25rem;
  background: linear-gradient(105deg, #cedcff 1.32%, #f0dbfd 99.72%);

  .container {
    position: relative;
    max-width: 75rem;
  }

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-count: auto;
    gap: 2.5rem;
    padding-top: 11rem;
    padding-bottom: 7.1875rem;

    h1 {
      line-height: 1.5;
      text-align: center;
    }

    .fido-group {
      display: flex;
      column-count: auto;
      gap: 2.5rem;

      img {
        width: 10.25rem;
        height: 5rem;
      }
    }
  }

  .box-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    max-width: 35rem;
    max-height: 30rem;
    padding: 3.75rem;
    background: $G00;
    box-shadow: 0px 10px 30px 0px rgba(48, 76, 137, 0.4);

    .text-group {
      display: flex;
      flex-direction: column;
      column-count: auto;
      gap: 1.5rem;
      margin-bottom: 3.75rem;

      p {
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.5;
      }

      span {
        font-size: 1.5rem;
        line-height: 1.5;
      }
    }

    .button-group {
      flex: 00;
    }

    button {
      max-width: 10.25rem;
    }

    .img-group {
      display: flex;
      justify-content: flex-end;
    }

    &:first-child {
      position: absolute;
      left: calc(50% - 320px);
      transform: translateX(-50%);
      bottom: -260px;

      .img-group {
        img {
          width: 6.25rem;
          height: 4.625rem;
        }
      }
    }
    &:last-child {
      position: absolute;
      right: calc(50% - 320px);
      transform: translateX(50%);
      bottom: -320px;

      .img-group {
        img {
          width: 4.625rem;
          height: 6.25rem;
        }
      }
    }
  }
}

.main-bottom {
  height: 24.375rem;
  background: $primary;
}

.try-for-free {
  padding: 7.5rem 0;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-count: auto;
    gap: 5rem;
  }

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-count: auto;
    gap: 1.5rem;
    text-align: center;
  }

  .sub-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-count: auto;
    gap: 0.75rem;

    p {
      font-size: 1.5rem;
      line-height: 1.5;
      color: $G80;
    }
    span {
      display: block;
      font-size: 1rem;
      line-height: 1.2;
      color: $G80;
    }
  }

  .input-group {
    display: flex;
    flex-direction: column;
    column-count: auto;
    gap: 2rem;
    width: 100%;
    max-width: 32.5rem;

    p.info-box {
      font-size: 1rem;
      line-height: 1.2;

      a {
        color: $primary;
      }
    }
  }

  .button-group {
    button {
      min-width: 11.25rem;
    }
  }
}

.warning-icon {
  width: 4rem;
  height: 4rem;
}

#Login,
#Authenticators,
#ChangePassword {
  padding: 13.75rem 0;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-count: auto;
    gap: 3.75rem;
  }

  .input-group {
    gap: 2rem;
    width: 100%;
    max-width: 30rem;
    margin: 0 auto;
    padding: 3.75rem 2.5rem;
    border-radius: 1.25rem;
    box-shadow: inset 0 0 0 1px $line-G40;

    h5 {
      font-size: 1.125rem;
    }

    .button-group {
      align-items: center;

      button {
        width: 100%;
      }

      p {
        display: flex;
        align-items: center;
        column-count: auto;
        gap: 0.5rem;
      }
    }

    > a {
      font-weight: 700;
      text-align: center;
    }

    &:nth-child(3) {
      .button-group {
        gap: 0.75rem;
      }
    }
  }
}

#Authenticators {
  .item-group {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 30rem;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    column-count: auto;
    gap: 2rem;

    &.empty {
      .empty-group {
        display: flex;
      }
    }

    &.tab-content {
      display: none;
      animation: OpacityAni 0.3s;
    }

    .default-group {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    .list-group {
      display: flex;
      flex-direction: column;
      align-items: center;
      column-count: auto;
      gap: 1.25rem;

      .item-list {
        width: 100%;
        display: flex;
        align-items: center;
        column-count: auto;
        gap: 1.5rem;
        padding: 1.25rem;
        border-radius: 1.5rem;
        border: 1px solid $line-G40;

        p {
          flex: 1;
          font-size: 1.125rem;
          font-weight: 700;
          line-height: 1.2;
        }

        .thumb-item {
          width: 4rem;
          height: 4rem;
        }
        .icon-plus,
        .icon-delete {
          cursor: pointer;
        }
      }

      .auth-list {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        column-count: auto;
        gap: 1.5rem;
        padding: 2rem 3.75rem;
        background: $G10;
        border-radius: 1.25rem;
        border: 0;

        .thumb-item {
          width: 4rem;
          height: 4rem;
        }

        .text-item {
          width: calc(100% - 9rem);
          display: flex;
          flex-direction: column;
          column-count: auto;
          gap: 0.5rem;

          p {
            font-size: 1.125rem;

            &:first-child {
              word-wrap: break-word;
              font-weight: 700;
              margin-bottom: 0.25rem;
            }
          }
        }

        .icon-delete {
          margin-left: auto;
          cursor: pointer;
        }
      }
    }

    .empty-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      column-count: auto;
      gap: 2rem;
      padding: 2rem;
      background: $G10;
      border-radius: 1.25rem;
      margin: 1.75rem 0;

      p {
        font-size: 1.125rem;
        font-weight: 700;
      }
    }

    .button-group {
      margin-top: 1.75rem;
    }
  }
}

#ChangePassword {
  .input-group {
    .input-item {
      gap: 0.75rem;
    }
  }
}

#Documentation {
  padding: 13.75rem 0;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-count: auto;
    gap: 3.75rem;
  }

  .container-inner {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 5rem;
  }

  .document-menu {
    width: 240px;
    ul {
      li {
        cursor: pointer;
      }
    }
  }

  .document-content {

    .content-item {
      display: flex;
      flex-direction: column;
      column-count: auto;
      margin-top: 2rem;
      gap: 2rem;

      h5 {
        color: $primary;
      }

      p {
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 2.0;
        padding-bottom : 1.1rem;
        
      }

      .context {
        display: flex;
        flex-direction: column;
        column-count: auto;
        margin-bottom: 2rem;
        gap: 1.5rem;

        h5 {
          font-size: 1.125rem;
          color: $G100;
        }

        h6 {
          color: $primary;
          font-size: 1.125rem;
          font-weight: 400;
        }

        h6.sub {
          color: $secondary;
        }

        ul {
          display: block;
          list-style-type: disc;
          margin-block-end: 1em;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          padding-inline-start: 30px;
        }
        
        ol {
          display: block;
          list-style-type: decimal;
          margin-top: 10px;
          padding-inline-start: 20px;
        }
        
        li {
          display: list-item;
          list-style: circle;
          margin-top: 10px;
        }

        p {
          font-size: 1.125rem;
          font-weight: 400;
          line-height: 2.0;
          color: $G60;
          padding-bottom : 1.1rem;
          
        }


        div.figure{
          text-align: center;
          font-weight: bold;
          margin-top: 0px;
        }

        .code-block {
          border-radius: 0.75rem;
          overflow: hidden;
        }
      }

      table {
        border: 1px solid #e8e8e8; /* 테두리 스타일 설정 */
      }
    }
  }
}

#DepthMenu {
  ul.depth1-menu {
    padding: 1.25rem;
    li {
      p {
        font-size: 1.125rem;
        padding: 1rem 0;
      }
      &.active {
        font-weight: bold;
        color: $primary;
      }

      ul.depth2-menu {
        padding: 0 0.5rem;

        li {
          p {
            font-size: 1rem;
            padding: 0.75rem;
            color: $G60;
          }

          &.active {
            p {
              font-weight: bold;
              color: $primary;
              background: rgba($color: #9cafd8, $alpha: 0.1);
              border-radius: 0.5rem;
            }
          }

          ul.depth3-menu {
            padding: 0.5rem;
            padding-right: 0;

            li {
              p {
                position: relative;
                font-size: 0.875rem;
                font-weight: 400;
                padding: 0.5rem 0.75rem;
                background: unset;
                color: $G60;

                &::before {
                  position: absolute;
                  top: 50%;
                  left: 0;
                  transform: translateY(-50%);
                  content: "";
                  display: inline-block;
                  height: 100%;
                  width: 1px;
                  background: $line-G40;
                }
              }

              &.active {
                p {
                  color: $primary;
                  &::before {
                    width: 2px;
                    background: $primary;
                  }
                }
              }

              ul.depth4-menu {
                padding: 0.5rem;
                padding-right: 0;

                li {
                  margin-left: 0.5rem;
                  background: $G10;

                  &::before {
                    display: none;
                  }

                  p {
                    font-size: 0.75rem;
                    font-weight: 400;
                    padding: 0.75rem;
                    color: $G60;
                    &::before {
                      display: none;
                    }
                  }

                  &:first-child {
                    border-top-left-radius: 0.5rem;
                    border-top-right-radius: 0.5rem;
                  }
                  &:last-child {
                    border-bottom-left-radius: 0.5rem;
                    border-bottom-right-radius: 0.5rem;
                  }

                  &.active {
                    p {
                      font-weight: bold;
                      text-decoration: underline;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#DialogPasskeySign {
  .steps {
    ul {
      gap: 3.375rem;

      li {
        &::after {
          width: 3.375rem;
        }
      }
    }
  }
}

#DialogFidoSign {
  .steps {
    ul {
      gap: 2rem;

      li {
        &::after {
          // left: 2.5rem;
          width: 2rem;
        }
      }
    }
  }
}

#DialogFidoAuth,
#DialogFidoDelete,
#DialogSubmitAlert,
#DialogPasskeyAdd {
  .text-group {
    p {
      font-weight: 400;
    }
  }
}

#DialogAddAuth {
  .modal-body {
    .text-group {
      p {
        max-width: 22.5rem;
        font-weight: 400;
      }
    }
    .input-group {
      max-width: 22.5rem;
      box-shadow: none;
      padding: unset;
    }
  }
}

#DialogForgetPassword,
#DialogChangePassword,
#DialogAppDownload {
  .modal-dialog {
    .modal-body {
      .text-group {
        p {
          max-width: 23.75rem;
          font-weight: 400;
        }
      }

      .input-group{
        input[readOnly] {
          background-color: #f2f2f2;
          color: #888;
          cursor: not-allowed;
        }
      }
    }
  }
}

#DialogSuccess {
  .modal-dialog {
    .modal-body {
      .text-group {
        span {
          max-width: 23.75rem;
        }
      }
    }
  }
}

#StoreArea {
  position: absolute;
  left: 0;
  bottom: 8.5rem;
  display: block;
  width: 100%;
  height: auto;
  padding: 1.5rem 0;
  background: #f4f7fe;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-count: auto;
    gap: 2.5rem;
    max-width: 34.375rem;
.logo {
  max-width: 5.875rem;

}
    .button-group {
      gap: 0.75rem;
    }
  }
}


#DialogTerms {
  .modal-dialog {
    max-width: 48rem;
  }

  iframe {
    width: 100%;
    height: 40rem;
  }
}