h1 {
  font-size: 3rem;
}
h4 {
  font-size: 1.5rem;
}
h5 {
  font-size: 1.125rem;
}

header {
  .header-container {
    padding: 1.5rem 1rem;
  }
  .header-left {
    .logo {
      height: 1.25rem;
    }
  }
  .header-center {
    ul {
      gap: 2rem;

      li {
        font-size: 1rem;
      }
    }
  }

  .user-info {
    span {
      font-size: 0.875rem;
    }
  }
}

button {
  &.btn-md {
    padding: 0.875rem 0;
    font-size: 0.875rem;
  }
  &.btn-lg {
    font-size: 1rem;
    padding: 1.125rem 0;
  }
  &.btn-icon {
    font-size: 0.875rem;
  }
}

.input-group {
  .input-item {
    .input {
      .timer {
        font-size: 1rem;
      }
    }
  }
  a.forget {
    font-size: 0.875rem;
  }
  .divider {
    p {
      font-size: 0.875rem;
    }
  }
}
input,
textarea {
  font-size: 1rem;
}
label {
  font-size: 0.875rem;
}

footer {
  &.pc {
    .footer-container {
      padding: 1.5rem 1rem;

      .footer-left {
        .brand-title {
          font-size: 0.875rem;
        }
        .text-item {
          span {
            font-size: 0.75rem;
          }
        }
      }

      .footer-right {
        ul {
          li {
            font-size: 0.75rem;

            &::after {
              height: 0.75rem;
            }
          }
        }
      }
    }
  }
}
