h1 {
  font-size: vw(32);
}

h4 {
  font-size: vw(20);
}

h5 {
  font-size: vw(14);
}

a {
  color: $primary;
}

button {
  gap: vw(4);

  &.btn-md {
    font-size: vw(16);
    padding: vw(16) 0;
    border-radius: vw(12);
  }
  &.btn-lg {
    font-size: vw(16);
    padding: vw(24) 0;
    border-radius: vw(12);
  }
  &.btn-primary-line {
    box-shadow: inset 0 0 0 vw(1) $primary;
  }
  &.btn-primary-fill {
    box-shadow: inset 0 0 0 vw(1) $primary;
  }
  &.btn-none-line {
    font-size: vw(16);
    padding: 0;
  }
  &.btn-header {
    font-size: vw(12);
    line-height: 1.5;
    height: unset;
    box-shadow: inset 0 0 0 1px $brand;
    padding: vw(8) vw(12);
    border-radius: vw(8);
    color: $brand;
  }
  &.gnb {
    img {
      width: vw(34);
      height: vw(34);
    }
  }

  i {
    img {
      width: vw(16);
    }
  }
}

header {
  .header-container {
    max-width: unset;
    padding: vw(16);
  }

  .header-left {
    .logo {
      max-width: vw(160);
      //height: vw(24);
      height: auto;
    }
  }
  .header-right {
    gap: vw(8);
  }
}

footer {
  &.mo {
    .footer-container {
      display: flex;
      flex-direction: column;
      column-count: auto;
      padding: vw(20);
      gap: vw(20);
    }

    .brand-title {
      font-size: vw(18);
      font-weight: 700;
    }

    .text-item {
      display: flex;
      flex-direction: column;
      column-count: auto;
      gap: vw(12);

      span {
        font-size: vw(14);

        &:last-child {
          color: $G50;
          line-height: 1;
        }
      }
    }

    ul {
      display: flex;
      column-count: auto;
      align-items: center;

      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: vw(12);
        font-weight: 700;

        &::after {
          content: "";
          width: vw(1);
          height: vw(14);
          background: $G50;
          margin: 0 vw(8);
        }

        &:last-child {
          &::after {
            content: none;
          }
        }
      }
    }
  }
}

.input-group {
  gap: vw(24);

  .input-item {
    gap: vw(8);
  }

  a.forget {
    font-size: vw(14);
  }

  .divider {
    gap: vw(24);
    p {
      text-align: center;
      font-size: vw(14);
    }
  }
}

input,
textarea {
  font-size: vw(18);
  padding: vw(12) 0;
}

label {
  font-size: vw(16);
}

span.error-message {
  font-size: vw(14);
}

.pc-only {
  display: none;
}
.mo-only {
  display: block;
}
