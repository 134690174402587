.modal {
  .modal-dialog {
    .modal-dialog-inner {
      padding: 2rem 1rem;
      gap: 1rem;
    }
    
    .modal-body {
      .img-item {
        display: flex;
        justify-content: center;

        img.qr-img {
          width: 6rem;
          height: 6rem;
        }
        img.step-img {
          width: 6rem;
          height: 6rem;
        }
      }
      .qr-img {
        padding: 0.5rem;

        img {
          width: 15rem;
        }
      }
    }
  }
}
