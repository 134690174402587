.tabs {
  .tab-item {
    font-size: 1rem;
  }
}

.modal {
  button.gnb {
    top: 1rem;
    right: 1rem;
  }

  .modal-dialog {
    transform: translate(-50%, -50%) scale(0.8);

    .modal-dialog-inner {
      padding: 1.5rem;
      gap: 1.5rem;
    }
  }
}
